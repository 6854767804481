@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
*{ margin: 0; padding: 0 ; box-sizing: border-box;}
html, body {width: 100%;height: 100%; overflow-x: hidden}
h1, p {
  font-family: Lato;
}
img{width:100%; height:auto}
.headding{color:red}
input:-webkit-autofill,input:hover:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
  box-shadow: 0 0 0 50px inset #fff;
  background-color:rgba(0,0,0,0) !important;
  /* background-color:rgba(0,0,0,0) !important; */
}
input, select, button{min-height:35px}
.ant-input:focus { border-color: #3e1c08 !important;}
.table-responsive .ant-table-body{width:100%; overflow: auto;}
table tr td, table tr th{word-break: normal !important;}
.ant-select-dropdown-hidden{z-index: 1;}
.ant-input-number{width:100%}
.ant-form-item.ant-form-item-with-help { margin-bottom: 0 !important;}
.ant-form-item{ margin-bottom: 19px !important;}
.m-b0{margin-bottom: 0 !important;}

/* .mobilefield{
  span{padding: 0 !important; background: none; border-width:0 0 1px 0; right:0 !important; left:auto !important; border-radius: 0}
  img{position: relative; top:-2px;}
  img + div{margin: 0 !important; }
  img + div div{padding: 0 !important; font-size: 17px; }
  input{padding-left: 10px !important}
} */
.full-width-form{min-width:100%;}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  appearance: none; 
  margin: 0; 
}
.mb-0{margin-bottom:0 !important}
.innerContainer{padding:15px 0}
.innerFields{min-width:100%}
.ant-picker{padding:0 11px !important}

.text_danger {
  color: #f14705;
  margin-bottom: 1rem;
  font-size: 10px
}

.ant-input[disabled] {
  color: black!important;
  background-color: #ffffff;
  cursor: not-allowed;
  opacity: 1;
}


.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  white-space: normal;
}



.chat-online {
  color: #34ce57
}

.chat-offline {
  color: #e4606d
}

.chat-messages {
  display: flex;
  flex-direction: column;
  /* max-height: 350px;
  overflow-y: scroll */
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}



.eye_btn {
  background: #f7bc33 !important;
  color: black !important;
}

.edit_btn {
  background: #329d27 !important;
  color: white !important;
}

.mobile_login {
  height: 100vh;
}

@media (max-width: 767px) {
  .mobile_login {
    height: 100vh;
  }
}

.basicbox > div {
  height: auto;
  min-height: 500px;
  width: 100%;
}

.login-box-msg {
  margin: 0;
  padding: 0 20px 20px;
  text-align: center;
}

.mobile_login_logo {
  width: auto;
  height: 70px;
  margin-bottom: 30px;
  border-radius: 15%;
  border: 1px solid silver;
}

.mainform img, .mainform p {
  display: block;
}

@media (max-width: 991px) {
  .mainform img, .mainform p {
    display: block;
  }
}

@media (max-width: 767px) {
  .mainform img, .mainform p {
    display: block;
  }
}

.mobile-login-form-button {
  background: #2DA89E;
  font-size: 12px;
  border-radius: 16px;
  border: none;
  border: 1px solid white !important;
  color: #fff !important;
}

.mobile-login-form-button:hover {
  color: #2DA89E !important;
  border: 1px solid #2DA89E !important;
  background: white;
}

.mobile-login-form-button:focus {
  color: #2DA89E !important;
  border: 1px solid #2DA89E !important;
  background: white;
}

.mobile-login-form-forgot {
  color: #347CDD;
}

.mobile-login-form-forgot:hover {
  color: #0067f5;
  border-bottom: 1px solid #0067f5;
}

.mobile-login-form-forgot:focus {
  color: #0067f5;
  border-bottom: 1px solid #0067f5;
}

@media (max-width: 767px) {
  .mobile-login-form-forgot {
    color: #39a4f5;
  }
}

.mobile-login-form-register {
  color: #3e1c08;
}

.mobile-login-form-register:hover {
  color: black;
}

.mobile-login-form-register:focus {
  color: black;
}

.mobile_mainform img {
  display: block;
}

@media (max-width: 479px) {
  .mobile_mainform img {
    display: block;
  }
}

.mobile_mainform p {
  display: none;
}

@media (max-width: 479px) {
  .mobile_mainform p {
    font-size: 16px;
    font-weight: bold;
    display: block;
  }
}

@media (max-width: 767px) {
  .mobile_mainform p {
    font-size: 18px;
    font-weight: bold;
    display: contents;
  }
}

.mobile-register-form-button {
  background: #d92222;
  font-size: 12px;
  border-radius: 16px;
  color: #fff !important;
}

.mobile-register-form-button:focus {
  color: black !important;
}

.mobile-register-already {
  color: #39a4f5;
}

.mobile-register-already:hover {
  color: #0067f5;
  border-bottom: 1px solid #0067f5;
}

.mobile-register-already:focus {
  color: #0067f5;
  border-bottom: 1px solid #0067f5;
}

@media (max-width: 767px) {
  .mobile-register-already {
    color: #39a4f5;
  }
}

@media (max-width: 991px) {
  .mobile_dashboard .ant-card-body {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
  }
}

@media (max-width: 767px) {
  .mobile_dashboard .ant-card-body {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
  }
}

@media (max-width: 991px) {
  .mobile_dashboard .ant-statistic-content {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .mobile_dashboard .ant-statistic-content {
    font-size: 14px;
  }
}

.sidebarDiv {
  width: 265px;
}

@media (max-width: 767px) {
  .sidebarDiv {
    width: 200px;
  }
}

@media (max-width: 767px) {
  .ant-table {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .logoDiv .innerlogo a {
    color: #fff !important;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    color: #585858;
    font-size: 16px;
    padding: 0 10px 10px;
  }
}

@media (max-width: 767px) {
  .ant-btn-danger {
    padding: 2px 8px;
  }
}

@media (max-width: 767px) {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 8px 8px;
  }
}

.mobile_verify_btn {
  background-color: #218838 !important;
  border: none !important;
}

@media (max-width: 991px) {
  .ant-table-container {
    overflow-x: scroll;
  }
}

@media (max-width: 767px) {
  .ant-table-container {
    overflow-x: scroll;
  }
}

.uploaderImg {
  border-radius: 80px;
}

@media (max-width: 767px) {
  .uploaderImg {
    width: auto !important;
    margin: 0 0 20px 0 !important;
  }
}

.ant-card-head-title .anticon-left {
  margin-right: 12px;
}

@media (max-width: 767px) {
  .headerdiv {
    position: fixed;
  }
}

@media (max-width: 767px) {
  .mobile-form-select {
    font-size: 11px;
    width: 80px;
    min-height: 24px;
    padding-top: 4px;
  }
}

@media (max-width: 767px) {
  .mobile_order_details .order-detail-heading {
    font-size: 10px;
  }
}

.mobile_order_details .ant-btn-primary {
  color: black;
}

@media (max-width: 767px) {
  .mobile_order_details .ant-btn-primary {
    color: black !important;
    font-size: 11px;
    padding: 2px 4px;
  }
}

@media (max-width: 767px) {
  .mobile_order_details .ant-card-head {
    font-size: 14px;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .mobile_order_details .ant-row {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .mobile_order_details .ant-card-body {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .mobile_order_details .ant-btn-danger {
    font-size: 11px;
    padding: 2px 4px;
    margin-top: 4px;
  }
}

@media (max-width: 767px) {
  .mobile_order_details .ant-modal-body {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .mobile_order_details .ant-modal-title {
    font-size: 12px !important;
  }
}

.mobile_order_details td {
  text-align: left;
  padding: 10px;
  font-weight: 300;
}

@media (max-width: 767px) {
  .ant-modal-body {
    font-size: 12px;
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .ant-modal-body td {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .ant-modal-title {
    font-size: 14px !important;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .ant-modal-footer .ant-btn-primary {
    color: black !important;
    font-size: 11px;
    padding: 2px 4px;
  }
}

@media (max-width: 767px) {
  .mobile_btn_between {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.mobile_btn_margin {
  margin-right: 1rem !important;
}

@media (max-width: 767px) {
  .mobile_btn_add {
    color: black !important;
    font-size: 11px;
    padding: 2px 4px;
    margin-bottom: 2rem;
  }
}

@media (max-width: 767px) {
  .ant-card-head-title {
    font-size: 1rem;
    font-weight: bold;
  }
}

.ant-form-horizontal {
  margin-top: 2rem !important;
}

.mobile_total_amt {
  padding: 0px !important;
}

@media (max-width: 767px) {
  .mobile_total_amt {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .ant-card-body {
    padding: 4px;
  }
}

@media (max-width: 767px) {
  .mobile_bold {
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .ant-picker-dropdown {
    width: auto;
  }
}

@media (max-width: 767px) {
  .ant-picker-content th {
    line-height: 0px;
  }
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-content, .ant-picker-panel-container .ant-picker-panel table {
  overflow-x: scroll !important;
}

@media (max-width: 767px) {
  .ant-picker-panel-container .ant-picker-panel .ant-picker-content td, .ant-picker-panel-container .ant-picker-panel table td {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .ant-picker-panel-container .ant-picker-panel .ant-picker-content, .ant-picker-panel-container .ant-picker-panel table {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .ant-picker-header-view {
    flex: none;
  }
}

@media (max-width: 767px) {
  .ant-picker-date-panel .ant-picker-body {
    padding: 0 4px;
  }
}

@media (max-width: 767px) {
  .ant-picker-header {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .ant-picker-date-panel .ant-picker-content th {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
    width: 210px;
  }
}

@media (max-width: 767px) {
  .ant-picker-header {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .ant-tooltip {
    top: 372px !important;
    padding-left: 125px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .intooltip .ant-tooltip-content .ant-tooltip-inner h4 {
    font-size: 12px !important;
  }
}

.intooltip .ant-tooltip-content .ant-tooltip-inner {
  background: #fcb800;
  padding: 0;
  width: 200px;
}

.ann_message {
  font-size: 12px;
  display: inline-block !important;
  width: 100px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

@media (max-width: 767px) {
  .ann_message {
    display: inline-block !important;
    width: 100px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

.ant-btn-primary {
  color: black;
}

.ant-btn-primary:hover {
  color: black;
}

@media (max-width: 767px) {
  .ant-btn-primary {
    padding: 2px 8px;
  }
}

@media (max-width: 767px) {
  .mobile-btn {
    padding: 2px 8px;
  }
}

@media (max-width: 767px) {
  .mobile_acdc {
    padding: 2px 5px;
    font-size: 10px;
  }
}

.mobile_user_table {
  padding: 0px 15px 15px;
}

@media (max-width: 767px) {
  .mobile_user_table {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .ant-btn-success {
    color: black;
    font-size: 11px;
    padding: 2px 4px;
    margin-top: 4px;
  }
}

@media (max-width: 767px) {
  .logoDiv {
    min-width: 0 !important;
  }
}

@media (max-width: 767px) {
  .ant-dropdown-menu-title-content {
    font-size: 12px !important;
  }
}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 0px 0;
  text-align: left;
  list-style-type: none;
  background-color: #d7d7d7;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.mobile_header {
  color: "#fff";
  height: 0;
}

@media (max-width: 767px) {
  .mobile_header {
    height: 0px;
  }
}

.mobile_clear {
  color: #325593 !important;
  background-color: #a3c1f6 !important;
  padding: 6px !important;
  border-radius: 12px;
  margin-bottom: 8px;
}

.mobile_clear:hover {
  color: white !important;
}

.mobile_clear:focus {
  color: white;
}

@media (max-width: 767px) {
  .mobile_dashboard .ant-card-bordered {
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  .multiSelectContainer li {
    padding: 4px !important;
  }
}

.highlightOption {
  background: #172f72 !important;
  color: #fff;
}

.chip {
  background: #172f72 !important;
}

@media (max-width: 767px) {
  .contentDiv {
    margin-left: 24px !important;
  }
}

@media (max-width: 767px) {
  .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
    margin-left: 10px !important;
  }
}

.mobile_invisiable {
  font-size: 20px;
  color: white;
}

@media (max-width: 767px) {
  .mobile_invisiable {
    display: none !important;
  }
}

.img_icon {
  height: 40px !important;
  width: 40px !important;
}

.div_tickets {
  background-color: #506273 !important;
}

.reload_icon {
  font-size: 24px;
}

.fa-spin-click {
  animation: fa-spin 1s infinite linear;
  color: #192e59;
}

.banner_sugg {
  font-size: 11px;
  padding-left: 8px;
}

.resend_link {
  color: #3e1c08;
}

.ant-form-horizontal {
  margin: 0 !important;
}

.basicbox_height {
  min-height: 700px !important;
}

.ant-layout-sider {
  background-color: #43B2DF !important;
}

.ant-menu-dark {
  background: #43B2DF  !important;
}

.ant-menu-item-selected {
  background-color: #024FD1        !important;
}

.ant-menu-inline.ant-menu-sub {
  background: white !important;
  color: #001529 !important;
}

.ant-menu-inline.ant-menu-sub span {
  color: black;
}

.ant-menu-inline.ant-menu-sub span:focus {
  color: #fff !important;
}

.ant-menu-inline.ant-menu-sub span:hover {
  color: #fff !important;
}

.headtoplink {
  float: right !important;
}

.report_reason {
  font-size: 12px;
}

.tag_price {
  font-size: 12px;
}

.order-detail-bold {
  color: black !important;
}

.innerlogo_res {
  justify-content: left !important;
  padding-left: 4px;
}

@media (max-width: 767px) {
  .innerlogo_res {
    justify-content: center !important;
    margin-left: 0 !important;
  }
}

.ant-menu.ant-menu-dark {
  color: white;
}

.pro_pic {
  border-radius: 80px;
}

.ant-menu-dark .ant-menu-item {
  color: white !important;
}

.ant-menu-sub .ant-menu-item-selected {
  background-color: #024FD1   !important;
}

.ant-menu-item .ant-menu-item-only-child {
  background-color: #024FD1   !important;
}

.ant-menu-dark .ant-menu-item > span > a {
  color: white !important;
}

.ant-btn-primary {
  background: #43B2DF;
  font-size: 12px;
  border: none;
  border: 1px solid white !important;
  color: #fff !important;
}

.ant-btn-primary:hover {
  color: #2DA89E !important;
  border: 1px solid #2DA89E !important;
  background: white;
}

.ant-btn-primary:focus {
  color: #2DA89E !important;
  border: 1px solid #2DA89E !important;
  background: white;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #b8d4e2 !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focus {
  border-color: #b8d4e2 !important;
}

.intooltip .ant-tooltip-content .ant-tooltip-inner {
  background: #8bb6cc !important;
}

.upload_logo {
  padding: 0px 4px !important;
  font-size: 12px !important;
  min-height: 28px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-select-multiple .ant-select-selector {
  padding: 0 0 0 4px !important;
  height: 38px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 7px 11px 0px 11px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px !important;
}

.time_table_text {
  font-size: 12px;
}

.time_table_text_header {
  font-size: 13px;
}
/*# sourceMappingURL=index.css.map */

@font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-Thin.14bb99bf.woff2) format('woff2'),
        url(/static/media/Urbanist-Thin.d4a1647c.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-ExtraLight.8468e7f7.woff2) format('woff2'),
        url(/static/media/Urbanist-ExtraLight.8cc1fd47.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-Light.89536858.woff2) format('woff2'),
        url(/static/media/Urbanist-Light.cf2f5603.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-Regular.1eef4c58.woff2) format('woff2'),
        url(/static/media/Urbanist-Regular.28488450.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-Medium.32521ed0.woff2) format('woff2'),
        url(/static/media/Urbanist-Medium.f1aa2120.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-SemiBold.00b4f24b.woff2) format('woff2'),
        url(/static/media/Urbanist-SemiBold.c16c3b13.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-ExtraBold.fb24178d.woff2) format('woff2'),
        url(/static/media/Urbanist-ExtraBold.490f9a27.woff) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-Bold.dad1a60f.woff2) format('woff2'),
        url(/static/media/Urbanist-Bold.3cbd130d.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url(/static/media/Urbanist-Black.cfe2cbfe.woff2) format('woff2'),
        url(/static/media/Urbanist-Black.a4afb465.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }


  /* font family end */



*{
    font-family: 'Urbanist' !important;
}

.basicbox .mainform{
    position: relative
}
.basicbox .effect-img{
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    pointer-events: none;
}

.login-box-msg{
    font-weight: 700;
}
.ant-form-item-explain-error{
    font-weight: 500;
}
.mobile-login-form-button{
    font-weight: 600;
}
.ant-input-affix-wrapper > .ant-input{
    font-weight: 600;
}
.ant-btn-text{
    font-weight: 500;
}
.headerdiv{
    background-color: #fff !important;
    box-shadow: 0px 0px 5px #0b0b0b61 !important;
    height: 70px;
}

.ant-menu-item-selected{
    background-color: #ffffff2e  !important;
}

.mobile_dashboard{

}
.ant-table-thead > tr > th{
    background-color: #c4c4c4;
}
.ant-table-tbody tr:nth-child(even){
    background-color: #ededed;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 12px 16px;
}
.ant-btn-primary{
    border: 1px solid transparent!important;
}
.ant-dropdown-menu{
    border-radius: 6px;
    overflow: hidden;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    background-color: #fff;
    padding: 7px 18px;
    color: #000;
}
.ant-dropdown-menu-item:not(:last-child){
    border-bottom: 1px solid #eaeaea;
}
.ant-table-cell .ant-btn .anticon{
    display: block;
}
.ant-table-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 35px !important;
}
.ant-table-pagination .ant-select-single .ant-select-selection-item{
    line-height: 20px;
}
.ant-table-pagination .ant-pagination-item{
    height: 35px;
    line-height: 35px;
}
.ant-table table{
    border: 1px solid rgb(223, 223, 223);
}

.sidebarDiv{
    flex: 0 0 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
    position: fixed;
    bottom: 0;
    left: 0;
    height: calc(100vh - 61px);
    overflow-y: auto;
}
main.ant-layout-content{
    padding-left: 260px;
    padding-top: 81px;
    min-height: 100vh;
}

.dashbboard-card-row .dash-card{
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    display: block;
    box-shadow: 0 0 10px #00000017;
    border: 1px solid #d5d5d5;
}
.dashbboard-card-row .dash-card .ant-statistic-title{
    font-weight: bold;
    color: #000;
}
.main-header-bar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
}

.com-modalUI{
    width: 450px;
}
.com-modalUI .modal-btn{
    margin-bottom: 0 !important;
}
.com-modalUI .ant-modal-content{
    border-radius: 7px;
}
.com-modalUI .ant-form-item{
    margin-bottom: 0 !important;
}
.com-modalUI .ant-form{
    width: auto;
    max-width: none;
}
.recover-modal .recover-btn{
    margin: 30px auto 0;
    display: block;
    font-size: 14px;
}
.ant-modal-mask{
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    background-color: rgb(199 199 199);
    font-weight: bold;
}
.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
    border-radius: 4px;
}
.ant-typography.ant-typography-secondary{
    color: #000;
}

@media(max-width: 1199px){
    .sidebarDiv{
        flex: 0 0 210px !important;
        max-width: 210px !important;
        min-width: 210px !important;
        width: 210px !important;
    }
    main.ant-layout-content{
        padding-left: 230px;
    }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sidebarDiv {
  border-right: 0;
  z-index: 1;
  /*background: #c75348; background: linear-gradient(@primary-color, @primary-color);*/
}
.sidebarDiv .ant-layout-sider-zero-width-trigger {
  display: none;
}
.headerdiv {
  background: #596B81;
  padding: 0 ;
  height: 61px;
  z-index: 5;
  box-shadow: 0px 0px 3px #58130d;
}
.contentDiv {
  height: calc(100vh - 61px);
  padding: 20px;
}
.cardtable {
  border-top: 1px solid #bf3427;
}
.cardtable ul.ant-pagination.ant-table-pagination {
  padding: 0 24px;
}
.cardtable .ant-table-thead > tr > th {
  white-space: nowrap;
}
.tablesearch + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.tablesearch + .ant-input-group-addon button {
  border-radius: 0 4px 4px 0;
}
.custom-filter-dropdown {
  padding: 5px;
  box-shadow: 0 0 2px #ccc;
  background: #fff;
}
.tabletopfield {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 676px) {
  .tabletopfield button span {
    display: none;
  }
  .contentDiv {
    padding: 15px;
  }
}
@media screen and (max-width: 576px) {
  .sidebarDiv {
    position: fixed;
    height: 100%;
    z-index: 2;
    top: 54px;
    padding-top: 6px;
  }
  .contentDiv {
    min-height: 100vh;
    padding-top: 71px;
  }
  .sidebarDiv [class="ant-layout-sider-zero-width-trigger"] {
    display: none;
    top: 17px;
    background: linear-gradient(90deg, #b6352a, #821918);
  }
  .headerdiv {
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.loader{position: fixed; top:0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); z-index: 1001;    text-align: center;}
.loader i{position: absolute; top: 50%; color:#fff;}
.loader svg{position: absolute; top: 50%; color:#fff; transform: translate(-50%,-50%)}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.labelbtn {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 32px;
}
.ant-upload-list-item-error {
  border-color: #d9d9d9 !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.labelbtn {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 32px;
}
.labelbtn input {
  position: absolute;
  visibility: hidden;
}
.hideFileBtn {
  visibility: hidden;
  position: absolute;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.wrapperClassName {
  background: #ffffff;
  padding: 5px 10px 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.toolbarStyle {
  background: #ffffff;
  border: 0;
  padding: 6px 0 0;
  margin: 0 !important;
}
.mainEditorStyle {
  background: #ffffff;
  min-height: 200px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}
.rdw-editor-wrapper img {
  width: auto;
}
.rdw-inline-wrapper .rdw-option-wrapper,
.rdw-link-wrapper .rdw-option-wrapper,
.rdw-link-disabled .rdw-option-wrapper,
.rdw-text-align-wrapper .rdw-option-wrapper,
.rdw-list-wrapper .rdw-option-wrapper,
.rdw-option-wrapper .rdw-option-wrapper,
.rdw-option-disabled .rdw-option-wrapper,
.rdw-inline-wrapper .rdw-dropdown-selectedtext,
.rdw-link-wrapper .rdw-dropdown-selectedtext,
.rdw-link-disabled .rdw-dropdown-selectedtext,
.rdw-text-align-wrapper .rdw-dropdown-selectedtext,
.rdw-list-wrapper .rdw-dropdown-selectedtext,
.rdw-option-wrapper .rdw-dropdown-selectedtext,
.rdw-option-disabled .rdw-dropdown-selectedtext {
  min-width: 35px;
  height: 35px;
  margin: 0 2px;
}
.rdw-dropdown-wrapper {
  height: 35px;
}
.rdw-dropdown-wrapper .rdw-dropdown-selectedtext {
  min-width: 35px;
  height: 35px;
  margin: 0 2px;
}
.rdw-editor-main {
  padding: 0 15px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.intooltip .ant-tooltip-content {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
}
.intooltip .ant-tooltip-content .ant-tooltip-arrow {
  height: 15px;
  width: 15px;
  right: -4px;
}
.intooltip .ant-tooltip-content .ant-tooltip-arrow:before {
  box-shadow: 3px -2px 2px rgba(0, 0, 0, 0.12) !important;
  height: 15px;
  width: 15px;
  right: 4px !important;
  background: #fcb800;
}
.intooltip .ant-tooltip-content .ant-tooltip-arrow-content {
  background: #fcb800;
  height: 20px;
  width: 20px;
}
.intooltip .ant-tooltip-content .ant-tooltip-inner {
  background: #fcb800;
  padding: 0;
}
.intooltip .ant-tooltip-content .ant-tooltip-inner h4 {
  padding: 10px !important;
  font-size: 14px;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  color: #fff;
}
.intooltip .ant-tooltip-content .ant-tooltip-inner .subcontent {
  padding: 10px !important;
  color: rgba(0, 0, 0, 0.25);
}
.intooltip .ant-tooltip-content .ant-tooltip-inner .subcontent p {
  margin-bottom: 10px !important;
}
.intooltip .ant-tooltip-content .ant-tooltip-inner .subcontent p:last-child {
  margin-bottom: 0 !important;
}
.intooltip .ant-tooltip-content .ant-tooltip-inner .subcontent p.active {
  color: rgba(0, 0, 0, 0.75);
}
.intooltip .ant-tooltip-content .ant-tooltip-inner .subcontent p.active i {
  color: #fcb800;
}
.intooltip .ant-tooltip-content .ant-tooltip-inner .subcontent i {
  font-size: 17px;
  position: relative;
  top: 1px;
}
.intooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow:before {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.sidemenu {
  color: #fff !important;
  overflow: hidden;
  height: 100%;
  border-right: 0;
  background: linear-gradient(90deg, #565656, #333333);
}
.sidemenu li {
  width: 100% !important;
  color: #fff !important;
  margin: 0 !important;
  padding-right: 0 !important;
  line-height: 48px !important;
  height: 49px !important;
  border-bottom: 1px solid #626262;
}
.sidemenu li ul {
  background: rgba(0, 0, 0, 0) !important;
}
.sidemenu li ul li {
  color: #fff !important;
  width: 100% !important;
  padding-left: 30px !important;
  line-height: 48px !important;
  height: 48px !important;
  border-bottom: 1px solid #626262;
  border-top: 1px solid #626262;
}
.sidemenu li ul li:last-child {
  margin: 0 !important;
}
.sidemenu li i {
  font-size: 17px !important;
}
.sidemenu li::after {
  content: none !important;
}
.sidemenu [class="ant-menu-submenu-title"]:after {
  content: '';
  background: #aa6168;
  width: 100%;
  height: 1px;
  left: 25px;
  position: absolute;
  bottom: 0;
}
.sidemenu a {
  color: #fcb800 !important;
}
.sidemenu .ant-menu-item-selected {
  background: #fffaed !important;
}
.sidemenu .ant-menu-item-selected a {
  color: #fcb800 !important;
}
.sidemenu li > div {
  line-height: 48px !important;
  height: 48px !important;
  margin: 0 !important;
}
.sidemenu li > div + div {
  line-height: auto !important;
  height: auto !important;
}
.sidemenu img {
  max-width: 25px;
  margin-right: 10px;
}
.sidemenu [class="ant-menu-item ant-menu-item-active"],
.sidemenu [class="ant-menu-item ant-menu-item-selected"],
.sidemenu [class="ant-menu-item ant-menu-item-active ant-menu-item-selected"] {
  background: linear-gradient(90deg, #333333, #565656);
  color: #fff !important;
  line-height: 48px !important;
  height: 49px !important;
  box-shadow: 0px 0px 4px inset #333;
}
.mobile_invisiable {
  color: #4F8FF9;
}
.ant-menu-dark {
  background-color: transparent !important;
}
.ant-menu.ant-menu-dark {
  /* color: #4F8FF9; */
  padding: 0 10px;
}
.ant-layout-sider {
  background-color: #4F8FF9 !important;
}
.ant-layout-sider-children {
  padding-top: 30px;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  border-radius: 4px;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 10px !important;
  margin: 0 0 10px 0 !important;
}
.ant-menu-inline .ant-menu-item {
  margin-bottom: 0 !important;
}
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 10px !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title:hover,
.ant-menu-item-only-child:hover {
  background-color: #ffffff2e !important;
}
.ant-menu-inline.ant-menu-sub {
  background-color: #ffffff2e !important;
}
.ant-menu-inline.ant-menu-sub span {
  color: #fff;
}
.ant-menu-sub .ant-menu-item-selected {
  background-color: #fff !important;
}
.ant-menu-sub .ant-menu-item-selected .anticon,
.ant-menu-sub .ant-menu-item-selected .anticon + span {
  color: #4F8FF9;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  padding: 8px;
}
.ant-menu-dark li.ant-menu-inline.ant-menu-sub {
  height: 35px;
  line-height: 35px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.logoDiv {
  max-width: 265px;
  min-width: 265px;
}
.logoDiv .innerlogo {
  /*max-width: 91px;*/
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoDiv .innerlogo a {
  color: #fff !important;
}
.logoDiv .innerlogo img {
  max-height: 61px;
  width: auto;
}
.topmenu {
  overflow-x: hidden;
  overflow-y: hidden;
}
.headtoplink {
  line-height: 60px;
  background: none;
  text-transform: uppercase;
  font-weight: 450;
  border: 0 !important;
  max-width: 300px;
}
.headtoplink a {
  color: #fff !important;
}
.headtoplink a:hover {
  color: #333 !important;
}
.headtoplink a:before {
  content: none;
}
.headtoplink li {
  border: 0 !important;
}
.headtoplink i {
  font-size: 16px !important;
  margin: 0 !important;
}
.togglemenu {
  display: none;
  margin: 21px 12px;
  font-size: 19px;
}
.headtoplink .ant-menu-overflowed-submenu {
  display: none;
}
@media screen and (max-width: 576px) {
  .logo {
    max-width: 70px;
    float: left;
    line-height: 60px;
    margin-left: 15px;
    position: relative;
    z-index: 10;
  }
  .headtoplink li {
    padding: 0 15px !important;
  }
  .togglemenu {
    display: inline-block;
    float: left;
  }
  .logoDiv {
    max-width: 150px;
  }
  .logoDiv .innerlogo {
    margin: 0;
    float: left;
    display: inline-block;
  }
}

/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800'); */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.basicpage {
  background: #b8d4e2;
  background: linear-gradient(180deg, #e3e0f1 0%, #b8d4e2 50%, #fefefe 100%);
}
.OTPInput {
  padding-left: 15px;
  letter-spacing: 60px;
  border: 0;
  background-position: bottom;
  background-size: 70px 3px;
  background-repeat: repeat-x;
  background-position-x: 40px;
}
/* @-webkit-keyframes mymove {
	0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}

@keyframes mymove {	
	0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}	
} */
.logo {
  max-width: 80px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 25px;
}
.basicbox {
  background: #ffffff;
  border-radius: 0;
  overflow: hidden;
  width: 100%;
  max-width: 950px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 13px #0000007d;
}
form {
  width: 100%;
  max-width: 290px;
}
.mainimg {
  height: 100%;
  background-image: url(https://images.pexels.com/photos/768474/pexels-photo-768474.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260);
  background-size: cover;
  position: relative;
  background-position: center;
}
.registerimg {
  background-image: url(https://images.pexels.com/photos/768474/pexels-photo-768474.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260);
}
.resetimg {
  background-image: url(https://images.unsplash.com/1/bag-and-hands.jpg?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=e67590234d9417585f3717e8fd804add&auto=format&fit=crop&w=747&q=80);
}
.mainimg:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(17, 151, 151, 0.2);
}
.mainform {
  height: 100%;
  padding: 25px;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 10px !important;
}
.eyeicon img {
  max-width: 15px;
  opacity: 0.4;
}
.PassPattern {
  margin: 0;
  font-size: 0.7rem;
  list-style: none;
}
.PassPattern .active {
  color: #6ad834;
}
/* .ant-tooltip-content .ant-tooltip-arrow{border-bottom-color:rgb(208, 190, 190)} */
/* .ant-tooltip-inner{background: rgb(208, 190, 190)} */
@media screen and (max-width: 600px) {
  .mainimg {
    display: none;
  }
}
.responsiveTable {
  width: 100%;
  overflow-x: auto;
}
.responsiveTable .ant-table-content {
  overflow-y: auto;
}
.responsiveTable .ant-table-tbody > tr > td {
  min-width: 200px;
  max-width: 50%;
}

