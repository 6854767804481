
@font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-Thin.woff2') format('woff2'),
        url('../../fonts/Urbanist-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-ExtraLight.woff2') format('woff2'),
        url('../../fonts/Urbanist-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-Light.woff2') format('woff2'),
        url('../../fonts/Urbanist-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-Regular.woff2') format('woff2'),
        url('../../fonts/Urbanist-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-Medium.woff2') format('woff2'),
        url('../../fonts/Urbanist-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-SemiBold.woff2') format('woff2'),
        url('../../fonts/Urbanist-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-ExtraBold.woff2') format('woff2'),
        url('../../fonts/Urbanist-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-Bold.woff2') format('woff2'),
        url('../../fonts/Urbanist-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Urbanist';
    src: url('../../fonts/Urbanist-Black.woff2') format('woff2'),
        url('../../fonts/Urbanist-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }


  /* font family end */



*{
    font-family: 'Urbanist' !important;
}

.basicbox .mainform{
    position: relative
}
.basicbox .effect-img{
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    pointer-events: none;
}

.login-box-msg{
    font-weight: 700;
}
.ant-form-item-explain-error{
    font-weight: 500;
}
.mobile-login-form-button{
    font-weight: 600;
}
.ant-input-affix-wrapper > .ant-input{
    font-weight: 600;
}
.ant-btn-text{
    font-weight: 500;
}
.headerdiv{
    background-color: #fff !important;
    box-shadow: 0px 0px 5px #0b0b0b61 !important;
    height: 70px;
}

.ant-menu-item-selected{
    background-color: #ffffff2e  !important;
}

.mobile_dashboard{

}
.ant-table-thead > tr > th{
    background-color: #c4c4c4;
}
.ant-table-tbody tr:nth-child(even){
    background-color: #ededed;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 12px 16px;
}
.ant-btn-primary{
    border: 1px solid transparent!important;
}
.ant-dropdown-menu{
    border-radius: 6px;
    overflow: hidden;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    background-color: #fff;
    padding: 7px 18px;
    color: #000;
}
.ant-dropdown-menu-item:not(:last-child){
    border-bottom: 1px solid #eaeaea;
}
.ant-table-cell .ant-btn .anticon{
    display: block;
}
.ant-table-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 35px !important;
}
.ant-table-pagination .ant-select-single .ant-select-selection-item{
    line-height: 20px;
}
.ant-table-pagination .ant-pagination-item{
    height: 35px;
    line-height: 35px;
}
.ant-table table{
    border: 1px solid rgb(223, 223, 223);
}

.sidebarDiv{
    flex: 0 0 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
    position: fixed;
    bottom: 0;
    left: 0;
    height: calc(100vh - 61px);
    overflow-y: auto;
}
main.ant-layout-content{
    padding-left: 260px;
    padding-top: 81px;
    min-height: 100vh;
}

.dashbboard-card-row .dash-card{
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    display: block;
    box-shadow: 0 0 10px #00000017;
    border: 1px solid #d5d5d5;
}
.dashbboard-card-row .dash-card .ant-statistic-title{
    font-weight: bold;
    color: #000;
}
.main-header-bar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
}

.com-modalUI{
    width: 450px;
}
.com-modalUI .modal-btn{
    margin-bottom: 0 !important;
}
.com-modalUI .ant-modal-content{
    border-radius: 7px;
}
.com-modalUI .ant-form-item{
    margin-bottom: 0 !important;
}
.com-modalUI .ant-form{
    width: auto;
    max-width: none;
}
.recover-modal .recover-btn{
    margin: 30px auto 0;
    display: block;
    font-size: 14px;
}
.ant-modal-mask{
    backdrop-filter: blur(4px);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    background-color: rgb(199 199 199);
    font-weight: bold;
}
.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
    border-radius: 4px;
}
.ant-typography.ant-typography-secondary{
    color: #000;
}

@media(max-width: 1199px){
    .sidebarDiv{
        flex: 0 0 210px !important;
        max-width: 210px !important;
        min-width: 210px !important;
        width: 210px !important;
    }
    main.ant-layout-content{
        padding-left: 230px;
    }
}