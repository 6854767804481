@import '~antd/lib/style/themes/default.less';
.sidemenu {color:#fff !important; overflow: hidden;
	height: 100%; border-right: 0; background:linear-gradient(90deg, #565656,#333333); 
	li{
	  width: 100% !important; color:#fff !important;  margin: 0 !important; padding-right: 0 !important; line-height: 48px !important; height:49px !important;  border-bottom: 1px solid #626262 ;
	  ul{background: rgba(0,0,0,0) !important;
			li{ color:#fff !important;  width: 100% !important;  padding-left: 30px !important; line-height: 48px !important; height:48px !important;  border-bottom: 1px solid #626262 ; border-top: 1px solid #626262 ;}
			li:last-child{margin: 0 !important;}
		}
		i{font-size: 17px !important}
		&::after{content: none !important}
	}
	[class="ant-menu-submenu-title"]:after{content: ''; background: rgba(170,97,104,1); width: 100%; height: 1px; left: 25px; position: absolute; bottom: 0;}
	
	a{color: @primary-color !important;}
	.ant-menu-item-selected{background:lighten(@primary-color,47%) !important;
		a{color:@primary-color !important; }
	}
	
	li > div{line-height: 48px !important; height:48px !important; margin: 0 !important}
	li > div+div{line-height: auto !important; height:auto !important;}
	img{max-width: 25px; margin-right: 10px}
	[class="ant-menu-item ant-menu-item-active"], [class="ant-menu-item ant-menu-item-selected"], [class="ant-menu-item ant-menu-item-active ant-menu-item-selected"]
	{background:linear-gradient(90deg, #333333,#565656); //linear-gradient(90deg, #e93e2f, #9c2318) !important;
		 color:#fff !important; line-height: 48px !important; height:49px !important; box-shadow: 0px 0px 4px inset #333; //transform: skewX(11deg) translateX(5px);
	}
  }


//   updated css

.mobile_invisiable{
    color: #4F8FF9;
}
.ant-menu-dark{
    background-color: transparent !important;
}
.ant-menu.ant-menu-dark{
    /* color: #4F8FF9; */
    padding: 0 10px;
}
.ant-layout-sider{
    background-color: #4F8FF9 !important;
}
.ant-layout-sider-children{
    padding-top: 30px;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title{
    border-radius: 4px;
}
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
    padding: 0 10px !important;
    margin: 0 0 10px 0 !important;
}
.ant-menu-inline .ant-menu-item{
    margin-bottom: 0 !important;
}
.ant-menu-inline .ant-menu-item:not(:last-child){
    margin-bottom: 10px !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title:hover, .ant-menu-item-only-child:hover{
    background-color: #ffffff2e  !important;
}
.ant-menu-inline.ant-menu-sub{
    background-color: #ffffff2e !important;
}
.ant-menu-inline.ant-menu-sub span{
    color: #fff;
}
.ant-menu-sub .ant-menu-item-selected{
    background-color: #fff !important;
}
.ant-menu-sub .ant-menu-item-selected .anticon, .ant-menu-sub .ant-menu-item-selected .anticon + span{
	color: #4F8FF9;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub{
	padding: 8px;
}
.ant-menu-dark li.ant-menu-inline.ant-menu-sub{
	height: 35px;
	line-height: 35px;
}
@primary-color: rgba(252, 184, 0, 1);@link-color: rgba(252, 184, 0, 1);@error-color: #f14705;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@input-hover-border-color: #d9d9d9;@success-color: #690;@warning-color: rgba(252, 184, 0, 1);@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .75);@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;